<template>
	<modal @update:show="onClose" :show="show">
		<h6 class="modal-title" slot="header">
			{{ $t('message.export_options') }}
		</h6>
		<div>
			<h6> {{ $t('message.language') }} </h6>
			<el-select v-model="exportLanguage">
				<el-option
					v-for="(lang, lang_index) in languages"
					:key="lang_index"
					:label="lang.label"
					:value="lang.value"
				/>
			</el-select>
		</div>
		<template slot="footer">
			<base-button type="link" class="ml-auto" @click="onClose()">
				{{ $t('message.close')}}
			</base-button>
			<base-button type="primary" @click="onSave">
				{{ $t('message.export') }}
			</base-button>
		</template>
    </modal>
</template>

<script>

import { mapGetters } from 'vuex';

import download from '@/util/download';

export default {
	name: 'PickingListExportForm',
	props: {
		items: {
			type: [Array, Object],
			required: true
		},
		show: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			exportLanguage: 'de',
			languages: [
				{
					label: this.$t('message.lang_de'),
					value: 'de',
				},
				{
					label: this.$t('message.lang_en'),
					value: 'en'
				}
			]
		}
	},
	computed: {
		...mapGetters([
			'project',
			'currentProjectAddress',
		])
	},
	methods: {
		report(lang) {
			let filteredItems = this.setFilterQuery();
			const url = 'pdf/logistic/'+this.project.id+'/kommissionierungsliste'
			+ '/'+ filteredItems
			+ "?lang="+lang
			+ (this.currentProjectAddress!=0 ? '&adr=' + this.currentProjectAddress : '')

			download(url, this.project.name + "-Kommissionierungsliste.pdf")
		},
		setFilterQuery() {
			const add = [];
			const mat = [];
			this.items.forEach((item) => {
				if (item.additional) {
					add.push(item.id);
				} else {
					mat.push(item.id);
				}
			});
			
			let filterMat = mat.join(',');
			let filterAdd = add.join(',');
			let filtered = `mat:${filterMat};add:${filterAdd}`;
		
			return filtered;
		},
		onSave() {
			this.report(this.exportLanguage);
		},
		onClose(state) {
			this.$emit('update:show', state);
		}
	}
}
</script>