<template>
  <modal @update:show="onClose" :show="show" class="price-list-modal">
    <h6 slot="header" class="modal-title">{{$t('material_additional.title')}}</h6>
    <div class="row">
      <div class="col-md-6">
        <base-input type="text" :label="$t('material_additional.key')+'*'" v-model="item.key"/>
        <base-input type="text" :label="$t('message.article')" v-model="item.article"/>
        <base-input type="number" :label="$t('material_additional.quantity')"  v-model="item.quantity"/>
        <base-input type="text" :label="$t('material_additional.unit')" v-model="item.unit"/>
        <base-input :label="$t('material_additional.category')">
            <el-select v-model="item.category_id" filterable>
                <el-option v-for="option in globalOptions.filter(o => o.type=='ARTKAT')"
                            :key="option.id"
                            :label="option.name"
                            :value="option.id">
                </el-option>
            </el-select>
        </base-input>
      </div>
      <div class="col-md-6">
        <base-input type="text" :label="$t('material_additional.pt_number')" v-model="item.pt_number"/>
        <div>
            <base-input :label="$t('material_additional.p_address')+' & '+$t('material_additional.ride')+'*'" >
              <el-select 
                          class="col-4"
                          v-model="item.p_address_id"
                          placeholder="Ort auswählen">
                <el-option v-for="p_address in projectAddresses"
                            :key="p_address.id"
                            :label="p_address.address.name"
                            :value="p_address.id">
                </el-option>
              </el-select>
              <el-select
                    class="select-primary col-4"
                    v-model="item.ride_id"
                    placeholder="Fahrzeug">
                <el-option v-for="ride in projectRides"
                          class="select-primary"
                          :value="ride.id"
                          :label="ride.license ? ride.vehicle.type + ' / '  + ride.license : ride.vehicle.type"
                          :key="ride.id">
                </el-option>
              </el-select>
              <span class="col-4 text-right">
                <el-tooltip :content="$t('message.add')" placement="top">
                    <i class="fas fa-plus-circle text-primary"
                      @click="addAddressAndRide"></i>
                </el-tooltip>
              </span>
            </base-input>
            <div class="additional" v-if="(additionalRides.length)">
              <div v-for="(newRide, counter) in additionalRides" :key="counter">
                <base-input >
                  <el-select 
                              class="col-4"
                              v-model="newRide.p_address_id"
                              placeholder="Ort auswählen">
                    <el-option v-for="p_address in projectAddresses"
                                :key="p_address.id"
                                :label="p_address.address.name"
                                :value="p_address.id">
                    </el-option>
                  </el-select>
                  <el-select
                        class="select-primary col-4"
                        v-model="newRide.ride_id"
                        placeholder="Fahrzeug">
                    <el-option v-for="ride in moreRides"
                              class="select-primary"
                              :value="ride.id"
                              :label="ride.license ? ride.vehicle.type + ' / '  + ride.license : ride.vehicle.type"
                              :key="ride.id">
                    </el-option>
                  </el-select>
                  <span class="col-4 text-right">
                    <el-tooltip :content="$t('message.delete')" placement="top">
                        <i class="fas fa-minus-circle"
                        @click="deleteAddressAndRide(counter)"></i>
                    </el-tooltip>
                  </span>
                </base-input>
              </div>
            </div>
        </div>
        <base-input type="number" :label="$t('material_additional.width')" v-model="item.width"/>
        <base-input type="number" :label="$t('material_additional.height')" v-model="item.height"/>
        <base-input :label="$t('material_additional.bhb_position')">
          <el-select v-model="item.bhb_position_id" filterable>
            <el-option v-for="option in options.filter(o => o.type=='BHBPOS')"
                        :key="option.id"
                        :label="option.name"
                        :value="option.id">
            </el-option>
          </el-select>
        </base-input>

      </div>
    </div>
    <template slot="footer">
      <base-button type="link" class="ml-auto" @click="$emit('update:show', false)">{{ $t('message.close') }}</base-button>
      <base-button type="primary" :disabled="$v.$invalid" @click="onSave()">
        {{ $t('message.save') }}
      </base-button>
      <base-button type="primary" @click="onSave(true)" :disabled="$v.$invalid">{{$t('data_input.save_new')}}</base-button>
    </template>
  </modal>
</template>
<script>

  import { mapGetters, mapActions } from 'vuex'
  import { required } from "vuelidate/lib/validators";

  export default {
    name: 'material-additional',
    computed: {
      ...mapGetters([
        'project',
        'rides',
        'projectAddresses',
        'options',
        'globalOptions',
      ]),
      projectRides() {
        const p_address = this.projectAddresses.find(pa => pa.id==this.item.p_address_id)
        return p_address ? this.rides.filter(r => r.address_id === p_address.address_id) : this.rides
      },
      moreRides() {
        const p_address = this.projectAddresses.find(pa => pa.id==this.additionalRides.p_address_id)
        return p_address ? this.rides.filter(r => r.address_id === p_address.address_id) : this.rides
      },
    },
    props: {
      item: Object,
      show: {
        type: Boolean,
        default: false
      },
    },
    watch: {
      'item.p_address_id' (val) {
        const p_address = this.projectAddresses.find(pa => pa.id === val)
        const rides = p_address ? this.rides.filter(r => r.address_id === p_address.address_id) : this.rides
        this.item.ride_id = rides.length>0 ? rides[0].id : null
      },
      'newRide.p_address_id' (val) {
        const p_address = this.projectAddresses.find(pa => pa.id === val)
        const rides = p_address ? this.rides.filter(r => r.address_id === p_address.address_id) : this.rides
        this.additionalRides.ride_id = rides.length>0 ? rides[0].id : null
      },
    },
    data() {
      return {
        additionalRides:[],
        newRide:[]
      }
    },
    validations: {
      item: {
        key: {required},
        p_address_id: {required},
        ride_id: {required},
      },
    },
    methods: {
      addAddressAndRide() {
        this.additionalRides.push({
          p_address_id: '',
          ride_id: ''
        })
      },
      deleteAddressAndRide(counter) {
        this.additionalRides.splice(counter,1);
      },
      onClose (state) {
        this.$emit('update:show', state)
      },
      onSave: async function(createAndNew = false) {
        this.item.project_id = this.project.id
        
        if (this.item.id) {
          delete this.item.user_komm_ref
          delete this.item.user_lade_ref
          delete this.item.user_rueck_ref
          delete this.item.bhb_position
          delete this.item.category
          delete this.item.ride
          delete this.item.additional
          delete this.item.extent
          await this.$store.dispatch('updateMaterial_additional',
            {id: this.item.id, data: this.item});
        } else {
          this.item.rides = [{
            p_address_id: this.item.p_address_id,
            ride_id: this.item.ride_id
          }].concat(this.additionalRides);

          for (let i = 0; i < this.item.rides.length; i++) {
            let matItem = {...this.item};
            matItem.id = '';
            matItem.p_address_id = this.item.rides[i].p_address_id;
            matItem.ride_id = this.item.rides[i].ride_id;
            if (matItem.p_address_id !== "" && matItem.ride_id !== "") {
              const result = await this.$store.dispatch('createMaterial_additional',
                {data: matItem});
              this.item.id = result.id;
              this.item.key = parseInt(matItem.key) + 1;
            }
          }
        }

        if (createAndNew === false) {
          this.$emit('update:show', false)
          this.$emit('change', false)
        } else {
          this.additionalRides = [];
          this.$emit('saveAndNew', this.item.key)
          this.$emit('change', false)
        }
      }
    },
  };
</script>
<style></style>
